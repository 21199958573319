@charset "utf-8";
@mixin colw-1($cols){
}
@mixin colw-2($cols){width: calc(100%/6 - ($cols - 1) * 24  / $cols);}
@mixin colw-3($cols){width: calc(100%/4 - ($cols - 1) * 24  / $cols);}
@mixin colw-4($cols){width: calc(100%/3 - ($cols - 1) * 24  / $cols);}
@mixin colw-5($cols){width: calc(100%/2.4 - ($cols - 1) * 24 / $cols);}
@mixin colw-6($cols){width: calc(100%/2 - ($cols - 1) * 24 / $cols);}
@mixin colw-7($cols){width: calc(100%/1.71 - ($cols - 1) * 24 / $cols);}
@mixin colw-8($cols){width: calc(100%/1.5 - ($cols - 1) * 24 / $cols);}
@mixin colw-9($cols){width: calc(100%/1.333 - ($cols - 1) * 24 / $cols);}
@mixin colw-10($cols){width: calc(100%/1.2 - ($cols - 1) * 24 / $cols);}
@mixin colw-11($cols){width: calc(100%/1.09 - ($cols - 1) * 24 / $cols);}
@mixin colw-12($cols){width: calc(100%);}


._example{
  ._col{
    text-align: center;
    background: #f0f0f0;
    padding: 1em;
  }
}///_example

._none{
  display: none!important;
}

/** category Navi */
@import "inc/categoryNav";

.row{
  display: flex;
  
  @media screen and (max-width: 767px) {
    flex-direction: column;
  }
  
  .col-2{
    box-sizing: border-box;
    width: 468px;
    margin-left: 24px;
    
    &:first-of-type{
      margin-left: 0;
    }
    
    @media screen and (max-width: 767px) {
      width: 100%;
      margin-left: auto;
      margin-right: auto;
    }
  }/** .col-2 */
  
  .col-3{
    box-sizing: border-box;
    width: 304px;
    margin-left: 24px;
    
    &:first-of-type{
      margin-left: 0;
    }
    
    @media screen and (max-width: 767px) {
      width: 100%;
      margin-left: auto;
      margin-right: auto;
    }
  }/** .col-3 */
  
  .col-4{
    box-sizing: border-box;
    width: 222px;
    margin-left: 24px;
    
    &:first-of-type{
      margin-left: 0;
    }
    
    @media screen and (max-width: 767px) {
      width: 100%;
      margin-left: auto;
      margin-right: auto;
    }
  }/** .col-4 */
  
  .col-6{
    box-sizing: border-box;
    width: 140px;
    margin-left: 24px;
    
    &:first-of-type{
      margin-left: 0;
    }
    
    @media screen and (max-width: 767px) {
      width: 100%;
      margin-left: auto;
      margin-right: auto;
    }
  }/** .col-6 */
}//.row

/**  */
.pc-block{
  display: block;
  
  @media screen and (max-width: 767px) {
    display: none;
  }
}

.sp-block{
  display: none;
  
  @media screen and (max-width: 767px) {
    display: block;
  }
}

._row{
  &:nth-of-type(n+2){
    margin-top: 36px;
  }
}//._row


._col{

}//._col


.col-1{
  width: calc(100% / 12 - (24px * (var(--cols) - 1)) / var(--cols))!important;
}
.col-2{
  width: calc(100% / 6 - (24px * (var(--cols) - 1)) / var(--cols))!important;
}
.col-3{
  width: calc(100% / 4 - (24px * (var(--cols) - 1)) / var(--cols))!important;
}
.col-4{
  width: calc(100% / 3 - (24px * (var(--cols) - 1)) / var(--cols))!important;
}
.col-5{
  width: calc(100% / 2.4 - (24px * (var(--cols) - 1)) / var(--cols))!important;
}
.col-6{
  width: calc(100% / 2 - (24px * (var(--cols) - 1)) / var(--cols))!important;
}
.col-7{
  width: calc(100% / 1.72 - (24px * (var(--cols) - 1)) / var(--cols))!important;
}
.col-8{
  width: calc(100% / 1.5 - (24px * (var(--cols) - 1)) / var(--cols))!important;
}
.col-9{
  width: calc(100% / 1.334 - (24px * (var(--cols) - 1)) / var(--cols))!important;
}
.col-10{
  width: calc(100% / 1.2 - (24px * (var(--cols) - 1)) / var(--cols))!important;
}
.col-11{
  width: calc(100% / 1.1 - (24px * (var(--cols) - 1)) / var(--cols))!important;
}
.col-12{
  width: 100%;
}

._cols2,
._cols3,
._cols4{
  display: flex;
  flex-wrap: wrap;

  &._center-v{
    align-items: center;
  }

  &._center-h{
    justify-content: center;
  }

  ._col{
    box-sizing: border-box;
    margin-left: 24px;
    flex: 0 0 auto;
  }//._col
}//._cols2-4

._cols2{
  position: relative;
  --cols: 2;
  --bg: #00c;

  ._col{
    width: calc(100%/2 - 12px);

    &:nth-of-type(2n+1){
      margin-left: 0;
    }

    &:nth-of-type(n+3){
      margin-top: 24px;
    }
  }//._col

  
}//._cols2

._cols3{
  --cols: 3;
  ._col{
    width: calc(100%/3 - 16px);
    
    &:nth-of-type(3n+1){
      margin-left: 0;
    }

    &:nth-of-type(n+4){
      margin-top: 24px;
    }
  }//._col
}//._cols3

._cols4{
  --cols: 4;
  ._col{
    width: calc(100%/4 - 18px);
    
    &:nth-of-type(4n+1){
      margin-left: 0;
    }

    &:nth-of-type(n+5){
      margin-top: 24px;
    }
  }//._col
}//._cols4


.pageNav{
  padding-bottom: 60px;
  .btn-text{
    margin: auto;
  }
}//.pageNav


.newsContent{

  .wrapper{
    width: 100%;
    max-width: 960px;
    padding: 60px 60px;
    font-size: 1.6rem;
  }

  .date{
    
  }//.date

  &-headline{
    margin: 32px 0;

    ._primary{
      font-size: 2.4rem;
      font-weight: 500;
      font-feature-settings : "palt";
      letter-spacing: .05em;
    }//._primary
  }//&-headline

  &-body{

    &-row{
      &:nth-of-type(n+2){
        margin-top: 36px;
      }
    }//&-row

    p{
      line-height: 1.7;
      font-weight: 400;
      &:nth-of-type(n+2){
        margin-top: 1.5em;
      }
    }
  }//&-body

  ._outline{
    display: table;
    border-collapse:collapse;

    &._fit{
      width: 100%;
    }

    dl{
      display: table-row;

      dt,
      dd{
        display: table-cell;
        border: solid 1px #ddd;
        padding: .5em 1em;
      }
      dt{
        background: #f0f0f0;
        white-space: nowrap;
      }
    }
  }//._outline

  ._notes{
    background: #f2f2f2;
    padding: 2em;
    border-radius: 6px;

    &-headline{

    }

    &-body{
      margin-top: 1.0em;
    }

    &-row{
      &:nth-of-type(n+2){
        margin-top: 1.5em;
      }
    }// &-row

    &-list{
      &-item{
        display: flex;
        align-items: center;

        &:before{
          content: "";
          display: inline-block;
          width: 6px;
          height: 6px;
          border-radius: 50%;
          margin-right: 10px;
          background: #666;
          flex: 0 0 auto;
        }

        &:nth-of-type(n+2){
          margin-top: 1em;
        }
      }//&-item
    }//&-list
  }//._notes
  
}//newsContent




@media screen and (max-width: 767px) {

  ._cols2,
  ._cols3,
  ._cols4{
    display: flex;
    flex-wrap: wrap;
    //flex-direction: column; 

    ._col{
      box-sizing: border-box;
      margin-left: 0px;
      width: 100%;
      flex: 0 0 auto;

      &:nth-of-type(n+2){
        margin-top: 24px;
      }
    }//._col

    *[class*="col-"]{
      color: #0c0!important;
      width: 100%!important;
    }
    
  }//._cols2-4

  ._sp-cols2{
    --cols: 2;
    display: flex;
    flex-direction: row;

    ._col{
      width: calc(100% / 2 - 6px);
      margin-left: 12px;
      margin-top: 0px;

      &:nth-of-type(3n+1){
        margin-left: auto;
      }

      &:nth-of-type(2n+1){
        margin-left: 0;
      }

      &:nth-of-type(n+2){
        margin-top: 0px;
      }

      &:nth-of-type(n+3){
        margin-top: 24px;
      }
    }//._col


    .col-1,
    .col-2,
    .col-3{
      width: calc(100% / 4 - (12px * (var(--cols) - 1)) / var(--cols))!important;
    }
    .col-4,
    .col-5{
      width: calc(100% / 3 - (12px * (var(--cols) - 1)) / var(--cols))!important;
    }
    .col-6{
      width: calc(100% / 2 - (12px * (var(--cols) - 1)) / var(--cols))!important;
    }

    .col-7,
    .col-8{
      width: calc(100% - (100% / 3 ) - (12px * (var(--cols) - 1)) / var(--cols))!important;
    }

    .col-9,
    .col-10,
    .col-11{
      width: calc(100% - (100% / 4) - (12px * (var(--cols) - 1)) / var(--cols))!important;
    }

    .col-12{
      width: 100%;
    }
  }//_sp-cols2


  .pageNav{
    .wrapper{
      padding: 16px 16px 0;
    }
  }//.pageNav


  .newsContent{
    .wrapper{
      padding: 32px 16px;
    }

    ._outline{
      display: block;
  
      dl{
        display: block;

        &:nth-of-type(n+2){
          margin-top: .5em;
        }

        dt,
        dd{
          display: block;
        }
      }//dl
      
    }//._outline


    
  }//.newsContent
}/** @media max-width: 767px */