@charset "utf-8";

section.categoryNav{
  background: #f2f2f2;
  border-top: solid 1px #ddd;
  padding: 2.4rem 0 3.6rem;
  
  .wrapper{
    position: relative;
  }
  
  a.btn-text{
    width: 100%;
  }
  
  a.toc{
    position: absolute;
    width: 18em;
    top: 0;
    right: 0;
  }/** .toc */

  .hl{
    position: relative;
    padding-bottom: 3.2rem;
    
    h2.ttl{
      padding: .3em 0;
      font-size: 2.1rem;
      font-weight: normal;
      letter-spacing: .08em;
      margin-bottom: 0;
    }
  }/** .hl */
  
  .col-cat{  
    border-left: solid 1px #ddd;
    padding-left: 18px;
    
    dt{
      font-weight: bold;
      font-size: 1.4rem;
    }
    dd{
      margin-top: .8em;
      
      li{
        margin-top: .5em;
        
        a{
          position: relative;
          display: inline-block;
          color: #333;
          text-decoration: none;
          padding-left: 16px;
          line-height: 1.3;

          &:before{
            content: "";
            display: inline-block;
            height: 6px;
            width: 6px;
            border-top: 1px solid #333;
            border-right: 1px solid #333;
            transform: rotate(45deg);
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            margin: auto;
          }
          
          &:hover{
            opacity: .6;
          }
        }
      }
    }
  }/** .col-cat */
  
  @media screen and (max-width: 767px) {
    padding: 1.8rem 0 3.6rem;
    
    .wrapper{
      padding: 0 1.6rem;
    }
    
    .hl{
      padding-bottom: 1.6rem;
      
      h2.ttl{
        font-size: 2.1rem;
        text-align: center;
        letter-spacing: .08em;
      }
      
      .toc{
        bottom: 0;
      }
    }/** .hl */
    
    .col-cat{
      border-left: none;
      border-bottom: solid 1px #ddd;
      padding-bottom: 1em;
      margin-bottom: 1em;
      margin-left: 0;
      
      &:last-of-type{
        margin-bottom: 3.6rem;
      }
    }/** .col-cat */    
    
    a.toc{
      position: relative;
      width: 100%;
    }/** .toc */
  }
}/** section.categoryNav */